$primary-color: #62D960;
$light-grey: #ddd;
$grey: #bbb;
$red: #d86969;
// $primary-color: #444;

%noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}