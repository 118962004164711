.pagination {
  font-family: 'Anton';
  font-size: 2em;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 70px;

  a, span {
    padding: 10px;
  }

  a:hover {
    color: $primary-color;
  }

  span.next, span.previous {
    color: #636363;
  }
}