footer {
  background-color: black;
  color: white;
  height: 200px;
  text-align: center;
  font-family: 'Anton';
  padding: 70px 0;

  a {
    color: $primary-color;

    &:hover {
      color: darken($primary-color, 20%);
    }
  }
}