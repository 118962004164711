// .content.menu {
//   z-index: 1000;
// }

// .menu-overlay {
//   background-color: black;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 999;
//   visibility: hidden;
//   transition: 300ms;
// }
  
//   nav {
//     display: grid;
//     justify-content: center;
//     justify-items: center;
//   }

//   &.open {
//     visibility: visible;
//     top: 230px;
//   }
// }

.codrops-header {
  font-family: 'Anton';
  height: 230px;
  background-color: $primary-color;
  margin-bottom: 100px;
  display: grid;
  justify-content: center;
  justify-items: center;

  // display: flex;
  // flex-direction: column;

  .navbtn.hidden {
    display: none;
  }

  .logo {
    width: 175px;
    padding-top: 33px;
  }

  .menu {
    position: relative;
    display: none;
    top: 7px;
    cursor: pointer;
    div {
      width: 35px;
      height: 5px;
      background-color: white;
      margin: 6px 0;
    }
  }

  nav {
    position: relative;
    top: 7px;
    // display: flex;
    // justify-content: center;
    // box-sizing: border-box;
    // -webkit-box-pack: center;
    // justify-content: space-around;
    // -webkit-box-align: end;
    width: 100%;
    max-width: 700px;
    // align-items: flex-end;
    
    a {
      margin: 0 10px;
      font-size: large;
      text-align: center;
      color: white;
      // width: 100px;
      // max-width: 940px;
      text-transform: uppercase;
      font-size: 35px;
      border-bottom: 10px solid transparent;
      -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
      letter-spacing: 3px;

      &:hover {
        border-bottom: 10px solid $primary-color;
        color: black;
        -webkit-text-fill-color: black; /* Will override color (regardless of order) */
      }
    }
  }

  @media screen and (max-width: 880px) {
    .menu {
      display: block;
    }

    .logo {
      width: 165px;
      padding-top: 17px;
    }

    nav {
      display: grid;
      // visibility: hidden;
      background-color: white;
      opacity: 0;
      // transform: translateY(-200%);
      // z-index: -1;
      // top: 20px;
      // transition: transform 300ms, opacity 300ms 200ms;

      a:nth-child(1) {
        transition-delay: .2s;
      }
      a:nth-child(2) {
        transition-delay: .3s;
      }
      a:nth-child(3) {
        transition-delay: .4s;
      }
      a:nth-child(4) {
        transition-delay: .5s;
      }

      a {
        opacity: 0;
        transition: all .2s ease;
        // transform: translateY(-10px);

        // color: black;
        // -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  
        // &:hover {
        //   border-bottom: 10px solid transparent;
        //   color: white;
        //   -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        // }
      }
    }

    #navbtn:checked {
      & ~ .menu-overlay {
        background: $primary-color;
        height: 100%;
        width: 100%;
        position: fixed;
        display: block;
        z-index: 9970;
      }

      & + a {
        z-index: 9990;
      }

      & ~ .menu {
        transform: rotate(45deg);
        z-index: 9990;
      
        div {
          background: black;
          transition: transform .2s ease;
          &:nth-child(1) {
            transform: translateY(12px) rotate(180deg);
          }
            
          &:nth-child(2) {
            opacity: 0;
          }
            
          &:nth-child(3) {
            transform: translateY(-10px) rotate(90deg);
          }
        }
      }

      & ~ nav {
        // display: block;
        // visibility: hidden;
        margin-top: 20px;
        z-index: 9990;
        background-color: $primary-color;
        opacity: 1;
        // transform: translateY(-200%);
        // z-index: -1;
        // top: 20px;
        // transition: transform 300ms, opacity 300ms 200ms;

        a {
          opacity: 1;
          // transform: translateY(0px);
        }
      }
    }

    &.navbar.open {
      padding-left: 0;
      padding-right: 0;

      nav {
        // z-index: 1;
        opacity: 1;
        // transform: translateY(0%);
        // visibility: visible;
        // align-content: flex-end;
        // flex-direction: column;
        
        top: 20px;

        a:hover {
          border-bottom: 10px solid transparent;
        }
      }
    }

    // #navbtn:checked ~ .menu-overlay {
    //   .menu-overlay {
    //     visibility: visible;
    //   }
    // }
  }
}

// .overlay__reveal {
//   // display: flex;
//   // justify-content: center;
//   // align-content: center;
  
//   img {
//     width: 275px;
//   }
// }

// @media screen and (max-width: 600px) {
//   .topnav a:not(:first-child) {display: none;}
//   .topnav a.icon {
//     float: right;
//     display: block;
//   }
// }

// @media screen and (max-width: 600px) {
//   .topnav.responsive {position: relative;}
//   .topnav.responsive .icon {
//     position: absolute;
//     right: 0;
//     top: 0;
//   }
//   .topnav.responsive a {
//     float: none;
//     display: block;
//     text-align: left;
//   }
// }