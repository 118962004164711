.overlay__content {
  
  blockquote {
    @extend %cc-blockquote;
    color: $primary-color;
    margin-left: 0;
    margin-right: 0;
    text-align: center;

    &.verse {
      @media screen and (max-width: 55em) {
        font-size: 1.7em;
      }
    }
  }

  .note {
    font-size: 0.7em;
    font-style: italic;
    text-align: center;
  }

  %cc-large-mobile {

    @media screen and (max-width: 55em) {
      font-size: 1.2em;
    }
  }

  .large-list {
    font-weight: bold;
    @extend %cc-large-mobile;
  }

  img {
    max-width: 100% !important;
  }

  a:not(.nounder) {
    border-bottom: 2px solid $primary-color;
    font-family: 'Amatic SC';
    font-weight: bold;

    &:hover {
      color: $primary-color;
    }
  }

  .cc-form {

    .qgroup {
      margin: 20px 0;
      @extend %noselect;
      max-width: 700px;

      label.title {
        @extend %cc-large-mobile;
        font-weight: bold;
      }

      .note {
        font-size: 1em;
        text-align: left;
        font-style: normal;
        margin: 15px 0;
        color: $primary-color;
        font-weight: bold;
        display: none;

        @media screen and (max-width: 55em) {
          font-size: 1.5em;
        }
      }

      .option {
        display: flex;
        cursor: pointer;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @media screen and (max-width: 55em) {
          margin-top: 10px;
        }

        .radiobg {
          margin-top: 15px;
          height: 25px;
          width: 25px;
          background-color: $light-grey;
          border-radius: 50%;

          .wrong, .correct {
            position: relative;
            top: -7px;
            display: none;
            font-size: 1em;
            left: -2px;
          }

          .wrong {
            color: $red;
          }

          .correct {
            color: $primary-color;
          }

          @media screen and (max-width: 55em) {
            margin-top: 0px;

            .wrong, .correct {
              font-size: 2em;
              top: -4px;
              left: 0px;
            }
          }
        }

        //On option mouse-over
        &:hover input ~ .radiobg {
          background-color: $grey;
        }

        input {
          opacity: 0;
          cursor: pointer;

          &:checked ~ {
            .radiobg {
              .wrong {
                display: block;
              }

              .correct {
                display: none;
              }
            }
          }
        }

        &.correct {
          input {
            &:checked ~ {
              .radiobg {
                .wrong {
                  display: none;
                }

                .correct {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}