.user-select-none {
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */      
}

.has-text-centered {
  text-align: center;
}

.has-primary-color {
  color: $primary-color;
}

@mixin cc-grid($cols) {
  display: grid;
  grid-template-columns: $cols;

  @media screen and (max-width: 55em) {
    grid-template-columns: auto;
  }
}

.cc-categories {
  margin: 0 auto;
  max-width: 600px;
  @include cc-grid(auto auto);
  @extend %cc-blockquote;
  font-size: 2.5em;
  a:hover {
    color: $primary-color;
  }

  &.list {
    @include cc-grid(auto);
    text-align: left;
    font-size: 2em;

    li {
      padding: 15px;
    }

    a {
      border-bottom: 2px solid $primary-color;
    }

    .subtitle {
      font-size: smaller;
      font-weight: lighter;
    }
  }
}

%cc-title {
  font-family: 'Anton';
  font-weight: normal;
  font-size: 3.15em;
  letter-spacing: 0.15rem;
}

%cc-subtitle {
  font-family: 'Buenard';
  font-size: 2.25em;
}

%cc-blockquote {
  font-family: 'Amatic SC';
  font-weight: bold;
}

.cc-maintitle {
  @extend %cc-title;
  text-transform: uppercase;
}

.cc-headtitle {
  @extend %cc-subtitle;
  font-family: 'Amatic SC';

  &:hover {
    color: $primary-color;
    // font-weight: bold;
  }
}

%cc-about-base {
  max-width: 777px;
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;

  .cc-title {
    @extend %cc-title;
    text-align: center;
    text-transform: uppercase;
  }

  .cc-subtitle {
    @extend %cc-subtitle;
  }

  blockquote {
    @extend %cc-blockquote;
    font-size: 4em;
  }

  .mail-link {
    text-decoration: underline;
    &:hover {
      color: $primary-color;
    }
  }

  @media screen and (max-width: 55em) {
    // width: 100%;
    font-size: 0.70rem;
    padding-left: 17px;
    padding-right: 17px;
  }

  @media screen and (max-width: 80em) {
  }
}

.cc-about {
  @extend %cc-about-base;
}

.cc-about-wider {
  @extend %cc-about-base;
  // width: 2000px;
  max-width: 1100px;
  margin-bottom: 100px;

  blockquote {
    font-size: 3em;
  }
}

.cc-company {
  text-transform: lowercase;
}

.cc-link {
  cursor: pointer;
  @extend %cc-blockquote;
  // font-size: 1.7em;
  border-bottom: 2px solid $primary-color;

  &:hover {
    color: $primary-color;
  }
}

.cc-form {
  input[type="text"] {
    border-radius: 5px;
    margin: 5px;
    padding: 3px 10px;
    font-family: 'Amatic SC';
    font-weight: bold;
    color: $primary-color;
  }

  button {
    border-radius: 7px;
    background-color: darken($primary-color, 20%);
    color: white;
    padding: 2px 15px;
    font-family: 'amatic sc';
    font-weight: bold;
    border-color: transparent;

    &:hover {
      background-color: darken($primary-color, 30%);
    }

    &:active {
      transform: translateY(2px);
    }
  }
}

.hide {
  display: none;
}

.highlight-text {
  color: $primary-color;
}

.cc-font {
  font-family: 'Amatic SC';
}

.cc-bold {
  font-weight: bold;
}